.hidden{
    display: none;    
}

#form{
    width: 90%;
    height: 90vh;
    max-width: 500px;
    max-height: 380px;
    border-radius: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: auto;
    position: relative;
    input{
        border: 1px solid black;
        .f-os(18px,black,0,600);
        width: 90%;
        margin-bottom: 30px;
        line-height: 3;
        border-radius: 5px;
        text-indent: 10px;
        &:first-child{
        
        }  
    }
    button{
        cursor: pointer;
        width: 100%;
        .f-os(18px,black,0,600);
        text-transform: uppercase;
        &.send{
            width: 90%;
            line-height: 3;
            background-color: #ecbf53;
            border: none;
            border-radius: 5px;
            &:hover{
                background-color: #26d007;    
            }
        }
        &.mfp-close{
            position: absolute;
            width: 20px;
            height: 20px;
            right: 10px;
            top: 10px;
            line-height: 0;
            font-size: 40px;
            font-weight: 600;
            color: black;
        }
    }
   
}