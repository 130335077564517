@import 'normalize.less';
@import 'smart-grid.less';
@import 'fonts.less';
@import 'popup.less';

.reset();

.container{
    .wrapper();
}

.super-wrapper{
    overflow-x: hidden !important;
    overflow-y: hidden !important;
}

.row{
    .row-flex();
}

.debug{
    .debug(rgba(0, 0, 0, 0.3), 1px solid #ff0);
}

body{
    overflow-x: hidden !important;
    position: relative;
}

a{
    text-decoration: none;
}

//Стили для h---------------------------------------------
div,dl,dt,dd,ul,li,h1,h2,h3,h4,h5,h6,pre,code,form,fieldset,input,textarea,p,blockquote,th,td {

}
table{
           
}

h1{

}

h2{
    .f-os(24px,black,0,600);
    text-transform: uppercase;
    padding-bottom: 33px;
}

h3{
    
}
a{
    
}
p{
    .f-os(14px,black,0,600);
    padding-bottom: 26px;
    line-height: 1.63;
}

article{
    width: 100%;
}
//Шрифты---------------------------------------------------

.f-os(@size: 15px; @color: black; @spacing: 0px; @fw: 400;){
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-size: @size;
    color: @color;
    letter-spacing: @spacing; 
    font-weight: @fw;
}

//-------------------------------------------------------------
.header-bkg{
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1000;
    width: 100%;
    .sm-block({
       position: relative; 
    });
}
.top{
    border-top: 5px solid #d0b298;
    min-height: 100px;
    border-bottom: 1px solid #e1d1c6;
    background-color: rgba(255,255,255,0.9);
}

.top-row{
    padding-top: 18px;
    padding-bottom: 20px;
}


.d{
    min-height: 1000px;
    background-color: aqua;
}

.top-logo-box{
    .col();
    .size(4);
    .size-md(12);
    .sm-block({
       text-align: center; 
    });
    a{
        img{
            
        }
    }
}

.top-address-spb{
    .col();
    .size(4);
    .size-md(6);
    .size-sm(12);
    .sm-block({
       padding-left: 0;
        text-align: center;
    });
    padding-top: 35px;
    padding-left: 3.8%;
    .top-address-spb-adr{
        .f-os(12px,black,0,600);
    }
    .top-address-spb-tell{
        .f-os(16px,black,0,600);    
    }
    
}

.top-address-msk{
    .col();
    .size(4);
    .size-md(6);
    .size-sm(12);
    .sm-block({
       text-align: center; 
    });
    padding-top: 34px;
    .top-address-msk-adr{
        .f-os(12px,black,0,600);    
    }
    .top-address-msk-tell{
        .f-os(16px,black,0,600);            
    }
}

.navsection{
    background-color: rgba(255,255,255,0.9);
    box-shadow: 0 10px 20px rgba(0,0,0,0.7);
}

.menu-box{
    .col();
    .size(7);
    .size-md920(12);
    .size-sm(12);
}

.menu{
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 25px;
    .xs-block({
       flex-direction: column;
       align-items: center;
        li{
            margin-top: 10px;
            margin-bottom: 5px;
            a{
                font-size: 16px;
            }
        }
    });
    li{
        a{
            text-transform: uppercase;
            .f-os(12px,black,0,600);
        }
    }
}

.menu-trigger{
    display: none;
    cursor: pointer;
    .xs-block({
       display: block; 
    });
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 15px;
    .f-os(28px,black,0,600);
}

.call-box{
    .col();
    .size(5);
    .size-sm(12);
    .size-md920(12);
    .md920-block({
        padding-left: 0;
        text-align: center;
        margin-bottom: 30px;
    });
    .sm-block({
        text-align: center;
        padding-right: 0;
    });
    text-align: right;
    padding-right: 7%;
    margin-top: 11px;
}

.btn-call{
    text-transform: uppercase;
    .f-os(12px,black,0,700);
    box-sizing: border-box;
    display: inline-flex;
    border: 2px solid #e1d1c6;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    padding-bottom: 12px;
    transition: all 0.4s;
    &:hover{
        background-color: #d0b298;
        transition: all 0.4s;
    }
}

.slider{
    width: 100%;

    div{
        width: 100%;
        height: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        text-align: center;
        
    }
    .slide-1{
        background-image: url(../img/slider/s-1.jpg);
    }
    .slide-2{
        background-image: url(../img/slider/s-2.jpg);
    }
    .slide-3{
        background-image: url(../img/slider/s-3.jpg);
    }
    .slide{
        min-height: 800px;
        .md920-block({
           min-height: 850px;
            h1{
                padding-top: 420px;
            }
        });
        .xxs-block({
            h1{
                font-size: 25px;
            }
            h2{
                font-size: 25px;
            }
        });
        .sm-block({
            min-height: 600px;
            h1{
                padding-top: 120px;
            }
        });
        h1{
            padding-top: 383px;
            text-transform: uppercase;
            .f-os(36px,black,0,600);
            box-sizing: border-box;
            padding-left: 5px;
            padding-right: 5px;
        }
        h2{
            text-transform: uppercase;
            .f-os(36px,black,0,600);
            padding-top: 23px;
            padding-bottom: 85px;
            box-sizing: border-box;
            padding-left: 5px;
            padding-right: 5px;
        }
    }
    
    
}

.slick-dots{
    bottom: 37px;
}



.slick-dotted.slick-slider {
    margin-bottom: 0;

}
.slick-dots li button{

}

.slick-dots li button::before{
    content: '';
    border: 2px solid #e1d1c6;
    width: 12px;
    height: 12px;
    opacity: 1;
}


li.slick-active button::before{
    opacity: 1;
    background-color: #979799;
}

.slider-btn{
    display: inline-flex;
    text-transform: uppercase;
    .f-os(14px,white,0,600);
    background-color: #6e5442;
    border: 3px solid #e1d1c6;
    box-sizing: border-box;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 21px;
    padding-bottom: 21px;
    justify-content: center;
    text-align: center;
    transition: all 0.4s;
    &:hover{
        background-color: #573b29;
        transition: all 0.4s;
    }
    margin-bottom: 100px;
}

.work{
    background-image: url(../img/bkg-1.jpg);
    background-size: cover;
    background-repeat: no-repeat;  
}

.sidebar-catalog{
    .col();
    .size(3);
    .size-sm660(12);
    z-index: 2;
}

.sidebar-catalog-title{
    text-transform: uppercase;
    .f-os(18px,black,0,600);
    margin-top: 117px;
}

.sidebar-catalog-items{
    text-transform: uppercase;
    margin-top: 35px;
    padding-bottom: 35px;
    position: relative;
    li{
        padding-bottom: 18.5px;
        a{
            .f-os(14px,black,0,400);    
        }
    }
    &::after{
        display: block;
        content: '';
        position: absolute;
        width: 90%;
        height: 2px;
        background-color: #e1d1c6;
        bottom: 0;
        left: 0;
    }
}

.work-box{
    .col();
    .size(9);
    .size-sm660(12);
    padding-top: 115px;
    min-height: 600px;
}

.production{
    background-image: url(../img/bkg-2.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.production-box{
    .col();
    .size(12);
}

.production-title{
    .f-os(24px,black,0,600);
    text-transform: uppercase;
    text-align: center;
    margin-top: 58px;
    
}

.production-items{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 60px;
    margin-bottom: 20px;
    li{
        text-align: center;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        width: 31%;
        .sm-block({
            width: 49%;
        });
        .xs-block({
           width: 100%; 
        });
        padding-bottom: 42px;
    }
}

.production-item-img{
    border: 4px solid white;
    img{
        display: block;
    }
    transition: all 0.4s;
    &:hover{
        img{
            transform: scale(1.025);
            opacity: 0.8;
        }
    }
}

.production-item-title{
    .f-os(14px,black,0,600);
    text-transform: uppercase;
    margin-top: 18px;
}

.production-item-variants{
    .f-os(12px,#646363,0,400);
    font-style: italic;
    margin-top: 10px;
}

.advantage-title-box{
    .col();
    .size(12);
    text-align: center;
    text-transform: uppercase;
    margin-top: 62px;
    margin-bottom: 56px;
}

.advantage-title{
    .f-os(24px,black,0,600);    
}

.advantage-items{
    margin-bottom: 120px;
    li{
        .col();
        .size(4);
        .size-sm(6);
        .size-xs(12);
        .xs-block({
           padding-bottom: 40px; 
        });
    } 
}

.advantage-item-title{
    display: flex;
    align-items: center;
    img{
        max-width: 68px;
    }
    span{
        margin-left: 15px;
        text-transform: uppercase;
        .f-os(16px,#6e3712,0,600);
    }
}

.advantage-item-txt{
    .f-os(14px,black,0,600);
    margin-top: 25px;
    line-height: 1.6;
    box-sizing: border-box;
    padding-right: 3%;
    .xs-block({
       text-align: justify; 
    });
}

.offer{
    background-color: #f1f1f1;
    background-image: url(../img/bkg-3.jpg);
    background-size: cover;
}

.offer-row{
    padding-bottom: 60px;       
}

.offer-img-box{
    .col();
    .size(5);
    .size-sm660(12);
}

.offer-txt-box{
    .col();
    .size(7);
    .size-sm660(12);
    padding-left: 0.5%;
}

.offer-img{ 
    margin-top: 60px;
    img{
        display: block;
        border: 4px solid white;
        .sm660-block({
           margin: 0 auto;
        });
    }
}

.offer-txt-title{
    text-transform: uppercase;
    .f-os(36px,black,0,700);
    margin-top: 105px;
    .sm660-block({
       margin-top: 30px; 
    });
}

.offer-txt{
    .f-os(30px,black,0,500);
    text-transform: uppercase;
    margin-top: 26px;
    line-height: 1.7;
    span{
        font-weight: 600;
    }
}

.offer-txt-format{
    .f-os(30px,black,0,500);
    text-transform: uppercase;
    text-decoration: underline; 
}

.footer{
    background-image: url(../img/footer-bkg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 540px;
}

.footer-logo-box{
    .col();
    .size(3);
    .size-md(7);
    .size-sm660(12);
    .sm660-block({
       text-align: center; 
    });
    margin-top: 48px;
}

.footer-logo-undertxt-1{
    .f-os(14px,#6e3712,0,600);
    margin-top: 18px;
    margin-bottom: 15px;
}

.footer-logo-undertxt-2{
    .f-os(14px,#6e3712,0,600);
    
}

.footer-logo-c{
    .f-os(14px,#6e3712,0,600);
    text-transform: uppercase;
    margin-top: 107px;
}

.footer-logo-conf{
    margin-top: 16px;
    a{
        .f-os(14px,#6e3712,0,600);    
    }
}

.footer-logo-count{
    margin-top: 20px;
}


.footer-catalog-box{
    .col();
    .size(2);
    .size-md(5);
    .size-sm660(12);
    .sm660-block({
       margin-left: 15px;
        text-align: center;
    });
    margin-left: 0;
}

.footer-news-box{
    .col();
    .size(3);
    .size-md(6);
    .size-sm660(12);
}

.footer-contacts-box{
    .col();
    .size(4);
    .size-md(6);
    .size-sm660(12);
    .md-block({
       margin-left: 15px; 
    });
    margin-left: 30px;
    padding-left: 7px;
}

.footer-catalog-items{
    margin-top: 85px;
    li{
        padding-bottom: 15px;
        a{
            text-transform: uppercase;
            .f-os(16px,#6e3712,0,700);
        
        }
    }
}

.footer-catalog-subcatalog{
    margin-top: 18px;
    margin-bottom: 20px;
    li{
        padding-bottom: 10px;
        a{
            text-transform: none;
            .f-os(14px,#6e3712,0,600);
            &:active{
                font-weight: 700;
            }
        }
    }
}

.footer-news-title{
    text-transform: uppercase;
    .f-os(16px,#6e3712,0,700);
    margin-top: 85px;
}

.footer-news-items{
    li{
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        padding-bottom: 10px;
    }
}

.footer-news-date{
    .f-os(12px,#6e3712,0,600);    
}

.footer-news-caption{
    text-transform: uppercase;
    .f-os(14px,#6e3712,0,600);
    margin-top: 7px;
}

.footer-news-txt{
    .f-os(12px,#6e3712,0,600);
    margin-top: 5px;
}

.footer-contacts-title{
    text-transform: uppercase;
    .f-os(16px,#6e3712,0,700);
    margin-top: 85px;
    margin-bottom: 20px;
}

.bold{
    font-weight: 700;
}

.footer-contacts-items{
    .f-os(14px,#6e3712,0,600);
    li{
        margin-bottom: 17px;
    }
}

.footer-contacts-email{
    position: relative;
    &::after{
        display: block;
        width: 100%;
        height: 1px;
        content: '';
        background-color: #6e3712;
        margin-top: 16px;
    }
}

.next-catalog{
    position: relative;
    .next-catalog-topbkg{
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;
    }
    .next-catalog-bottombkg{
        position: absolute;
        z-index: 1;
        bottom: 0;
        right: 30px;
    }
}

.next-t-bkg{
    background-image: url(../img/next-t-bkg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
}

.next-t-bkg-txt{
    .col();
    .size(12);
    margin-top: 270px;
    margin-bottom: 47px;
    text-transform: uppercase;
    .f-os(24px,black,0,600);
    .md-block({
       margin-top: 310px; 
    });
    .md920-block({
       margin-top: 400px; 
    });
    .sm-block({
       margin-top: 50px; 
    });
}

.next-sidebar-catalog-title{
    margin-top: 50px;
}

.next-catalog-box{
    .col();
    .size(9);
    .size-sm660(12);
    padding-top: 20px;
    min-height: 600px;
    z-index: 2;
    h2{
        padding-bottom: 20px;
    }
    p{
        text-align: justify;    
    }
}

.pagemenu ul{
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 25px;
    box-sizing: border-box;
    padding-left: 5px;
    margin-bottom: 9px;
    .sm-block({
       padding-left: 0; 
    });
    li{
        a{
            .f-os(12px,black,0.4px,400);
        
            transition: all 0.2s;
            &:hover{
                color: black;
            }
        }
        &::after{
            content: '|';
            display: inline-block;
            margin-right: 3px;
            color: black;
        }     
    }       
}

.pagemenu ul li:last-of-type{
    a{
    }
    &::after{
        display: none;
    }
}

.next-catalog-items{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    z-index: 2;
    margin-bottom: 130px;
    li{
        width: 31%;
        .md-block({
           width: 48%; 
        });
        .xs470-block({
           width: 100%; 
        });
        
        background-color: #f6f6f6;
        min-height: 200px;
        margin-bottom: 28px;
        z-index: 2;
        box-sizing: border-box;
        padding-top: 21px;
        padding-left: 18px;
        padding-right: 18px;
        padding-bottom: 27px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    img{
        height: auto;
    }
}

.next-catalog-item-title{
    text-transform: uppercase;
    .f-os(14px,black,0,600);
    text-align: center;
    padding-top: 15px;
    line-height: 1.3;
}

.next-catalog-item-price-box{
    display: flex;
    align-items: flex-end;
    margin-top: 30px;
}

.next-catalog-item-price{
    width: 50%;
    text-align: right;
    .f-os(24px,#6e3712,0,600);
    span{
        font-size: 14px;
    }
}

.next-catalog-item-measure{
    width: 50%;
    text-align: center;
    .f-os(12px,#151515,0,600);
    span{
        position: relative;
        bottom: 3px;
    }
}

.sidebar-spec-ref{
    margin-top: 70px;
    display: block;
}

.sidebar-spec-box{
    width: 100%;
    background-color: #f3f3f3;
    box-sizing: border-box;
    padding-top: 15px;
    padding-left: 11px;
    padding-right: 11px;
    padding-bottom: 32px;
    img{
        
        margin: 0 auto;
        display: block;
    }
    .sm660-block({
       display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    });
}

.sidebar-spec-title{
    text-transform: uppercase;
    .f-os(30px,#141414,0,700);
    margin-top: 25px;
    line-height: 1.25;
    .md-block({
       font-size: 20px; 
    });
    .md920-block({
       font-size: 15px; 
    });
}

.sidebar-spec-txt{
    margin-top: 13px;
    text-transform: uppercase;
    .f-os(18px,#141414,0,400);
    line-height: 1.75;
    span{
        font-weight: 600;
    }
    .md-block({
       font-size: 14px; 
    });
    .md920-block({
       font-size: 12px; 
    });
    .sm660-block({
       text-align: center; 
    });
}

.sidebar-spec-format{
    text-transform: uppercase;
    text-decoration: underline;
    .f-os(18px,#141414,0,400);
    .md920-block({
       font-size: 12px; 
    });
}

.sidebar-spec-img{
    border: 4px solid white; 
    display: inline-flex;
    
}


